import React, { Component } from 'react'
import { BasketContext } from './BasketContext';

export default class CheckoutProduct extends Component {
    static contextType = BasketContext;

    render() {
        return (
            <div className="checkoutProduct">
                <img className="checkoutProduct__image" src={this.props.image} alt="" />
                <div className="checkoutProduct__info">
                    <div className="checkoutProduct_title">
                    {this.props.title}
                    </div>
                    <div className="font_12 green">
                        In Stock 
                    </div>
                    <div className="font_12">
                    Ships from and sold by Amazon.ca
                    </div>
                    <div className="font_12">
                    Eligible for FREE Shipping 
                    </div>
                    <div>
                        <button onClick={() => { this.context.deleteItem(this.props.id) }}>Delete</button>
                    </div>
                </div>
                <div className="checkoutProduct__price">
                CDN$ {this.props.price}
                </div>
            </div>
        )
    }
}
