import React, { Component } from 'react';
import {BasketContext} from './BasketContext';

export default class Product extends Component {

    static contextType = BasketContext;

    render() {
        return (
            <div className="product">
                <div className="product__info">
                    <p>{this.props.title}</p>
                    <p className="product__price">
                        <strong>CDN$ {this.props.price}</strong>
                    </p>
                </div>

                <img src={this.props.image} alt="" />
                <button onClick={()=> {this.context.addItem({...this.props})}}>Add to Cart</button>
            </div>
        )
    }
}
