import React, { Component } from 'react';
import { Input, Select, Button } from 'antd';
import { Link } from "react-router-dom";
import { SearchOutlined } from '@ant-design/icons';
import { BasketContext } from './BasketContext';
import logo from './images/logo.png';

const { Option } = Select;
const { Search } = Input;
const searchBtn = <Button style={{backgroundColor:"#febd69", backgroundSize:"cover", border: "none"}} icon={<SearchOutlined />} />

export default class Header extends Component {
    static contextType = BasketContext;

    render() {
        return (
            <div className="header">
                <Link to="/" >
                    <img
                        className="header__logo"
                        src={logo}
                        alt=""
                    />
                </Link>
                <div className="header__search">
                    <Input.Group size="large" compact style={{display:"flex"}} >
                        <Select size="large" defaultValue="All" dropdownMatchSelectWidth={false}>
                            <Option value="allDepartments">All Departments</Option>
                            <Option value="alexaSkills">Alexa Skills</Option>
                            <Option value="ad">Amazon Devices</Option>
                            <Option value="awd">Amazon Warehouse Deals</Option>
                            <Option value="ag">Apps & Games</Option>
                            <Option value="aa">Audible Audiobooks</Option>
                            <Option value="au">Automotive</Option>
                            <Option value="be">Beauty</Option>
                            <Option value="bo">Books</Option>
                            <Option value="ca">Clothing & Accessories</Option>
                        </Select>
                        <Search enterButton={searchBtn} size="large" />
                    </Input.Group>
                </div>
                <div className="header__nav">
                    {/* <Link to="/login" className="header__link"> */}
                    <Link to="/" className="header__link">
                        <div className="header__option">
                            <span className="header__optionLineOne">Hello, Sign in</span>
                            <span className="header__optionLineTwo">Account & Lists</span>
                        </div>
                    </Link>
                    <Link to="/" className="header__link">
                        <div className="header__option">
                            <span className="header__optionLineOne">Returns</span>
                            <span className="header__optionLineTwo">& Orders</span>
                        </div>
                    </Link>
                    <Link to="/checkout" className="header__link">
                        <div className="header__optionBasket">
                            <span className="header__basketCount">{this.context.basket.length}</span>
                            <span className="header__optionLineThree">Cart</span>
                        </div>
                    </Link>
                </div>
            </div>
        )
    }
}
