import React, { Component } from 'react'
import Product from './Product'

export default class Home extends Component {
    render() {
        return (
            <div className="home">
                <img
                    className="home__img"
                    /* src="https://images-na.ssl-images-amazon.com/images/G/15/Advertising2020/StarzNov13SP2/1500x600-EN._CB416321757_.jpg" */
                    src="https://images-na.ssl-images-amazon.com/images/G/15/digital/video/merch/2020/Other/BRND_MTH20_00000_GWBleedingHero_1500x600_Final_en-CA_FT_PVD5870._CB418053281_.jpg"
                    alt=""
                />
                <div className="home__row">
                    <Product
                        id={"1"}
                        title={"Winter Warm Wool Socks,Homga 6 Pack Womens Wool Socks Men Socks"}
                        price={16.99}
                        rating={5}
                        image={"https://m.media-amazon.com/images/I/61TO20CJYiL._AC_SY200_.jpg"}
                    />
                    <Product
                        id={"2"}
                        title={"New Alienware m17 R3 17.3 inch FHD Gaming Laptop (Luna Light)"}
                        price={979.99}
                        rating={3}
                        image={"https://m.media-amazon.com/images/I/71hhY4ikVwL._AC_UL320_.jpg"}
                    />
                     <Product
                        id={"3"}
                        title={"Single Monitor Mount - Articulating Gas Spring Monitor Arm, Adjustable VESA Mount Desk Stand"}
                        price={125.99}
                        rating={5}
                        image={"https://m.media-amazon.com/images/I/41ZaBGV+KUL._AC_SY200_.jpg"}
                    />
                    <Product
                        id={"4"}
                        title={"TheFitLife Exercise and Resistance Bands Set - Stackable up to 150 lbs Workout Tubes for Indoor"}
                        price={35.99}
                        rating={4}
                        image={"https://m.media-amazon.com/images/I/51xSaFzEx1L._AC_SY200_.jpg"}
                    />
                </div>
                <div className="home__row">
                    <Product
                        id={"5"}
                        title={"AmazonBasics BIFMA Certified Gaming/Racing Style Office Chair - with Removable Headrest"}
                        price={325.99}
                        rating={5}
                        image={"https://images-na.ssl-images-amazon.com/images/I/61JUe6eJUZL.jpg"}
                    />
                    <Product
                        id={"6"}
                        title={"TIMEYARD Macrame Plant Hangers Set of 4 Indoor Wall Hanging Planter Basket Flower Pot Holder"}
                        price={19.99}
                        rating={5}
                        image={"https://images-na.ssl-images-amazon.com/images/I/51hEgYPeeiL._SCLZZZZZZZ___AC_SY200_.jpg"}
                    />
                     <Product
                        id={"7"}
                        title={"KitchenAid KHMB732ER Cordless Hand Mixer, 7 Speed, Empire Red"}
                        price={28.99}
                        rating={5}
                        image={"https://m.media-amazon.com/images/I/31nz68z6O4L._AC_SY200_.jpg"}
                    />
                    <Product
                        id={"8"}
                        title={"Anjou 20 Packs Bath Bombs Gift Set, Scented Vegan Natural Essential Oils Spa Bath Fizzies"}
                        price={30.99}
                        rating={5}
                        image={"https://m.media-amazon.com/images/I/5147eWsK8pL._AC_SY200_.jpg"}
                    />
                </div>
            </div>
        )
    }
}
