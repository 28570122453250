import React, { Component } from 'react';
import { BasketContext } from './BasketContext';
import CheckoutProduct from './CheckoutProduct'
import { Checkbox } from 'antd';

export default class Checkout extends Component {

    static contextType = BasketContext;

    getTotal = () => {
        let result = 0;
        for (let i = 0; i < this.context.basket.length; i++) {
           result += this.context.basket[i].price;
        }

        return result;
    }

    render() {
        return (
            <div className="checkout">
                <div>
                    <img className="checkout__ad" src="https://images-na.ssl-images-amazon.com/images/G/01/credit/img16/CCMP/newstorefront/amazoncards/desktop-CBCC-unloggedin-header.png" alt="" />
                </div>
                <div className="checkout_left_left">
                    <div className="checkout_left">

                        {
                            (this.context.basket.length === 0)
                                ? (
                                    <div className="checkout_left_left">
                                        <img className="checkout__empty" src="https://m.media-amazon.com/images/G/15/cart/empty/kettle-desaturated._CB424694027_.svg" alt="" />
                                        <h2>Your Amazon Cart is empty</h2>
                                    </div>
                                )

                                : (<div>
                                    <h2>Shopping Cart</h2>
                                    {
                                        this.context.basket.map(item => <CheckoutProduct key={item.id} {...item} />)
                                    }
                                </div>)
                        }

                    </div>
                    {
                        (this.context.basket.length > 0)
                            ? (
                                <div className="checkout_right">
                                    <p>Subtotal ({this.context.basket.length === 1 ?
                                    this.context.basket.length + " item)": this.context.basket.length + " items)"}</p>
                                     <p><strong>CDN$ {this.getTotal()}</strong></p>
                                     <Checkbox>This order contains a gift</Checkbox>
                                     <button className="yellow_button" >Proceed to Checkout</button>
                                </div>
                            )
                            : null
                    }

                </div>
            </div>
        )
    }
}
