import React from 'react';
import './App.css';
import { BrowserRouter, Switch, Route } from "react-router-dom";
import Header from './Header';
import 'antd/dist/antd.css';
import Home from './Home';
import { BasketContext } from './BasketContext';
import Checkout from './Checkout'


export default class App extends React.Component {
  constructor(props) {
    super(props);

    this.addItem = (item) => {
      this.setState({ basket: [...this.state.basket, item] });
    };

    this.deleteItem = (id) => {
      this.setState({ basket: this.state.basket.filter(item => item.id !==id) });
    };

    this.state = {
      basket: [],
      addItem: this.addItem,
      deleteItem: this.deleteItem,

    }
  }

  render() {
    return (

      <BrowserRouter>
        <Switch>
          <BasketContext.Provider value={this.state}>
            <Route exact path="/checkout">
              <Header />
              <Checkout />
            </Route>
            <Route exact path="/login">
              <h1>login</h1>
            </Route>
            <Route exact path="/" >
              <Header />
              <Home />
            </Route>
          </BasketContext.Provider>
        </Switch>
      </BrowserRouter>
    );
  }

}
